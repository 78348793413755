import React from 'react';
// import Layout from '../templates/layout';
// import Header from '../components/header';
// import Footer from '../components/footer';

// TODO finish the page

const headerData = {
  title: 'Page not found, so sorry!',
};

const NotFoundPage = () => {
  return <div>{headerData.title}</div>;
};
export default NotFoundPage;
